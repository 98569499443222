import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUser, faMapMarkerAlt, faEnvelope, faReceipt, faDownload } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logo from '../../assets/apcl.jpg'; // Replace with your logo path

const PurchaseTemplate = ({ showTemplate, setShowTemplate, setShowPoForm, Data }) => {
  const templateRef = useRef(null);

  const handleDownload = async () => {
  const element = templateRef.current;

  // Temporarily set the overflow to 'visible' to capture all rows
  const originalStyle = element.style.overflow;
  element.style.overflow = 'visible';
  
  // Temporarily set the height to fit all content
  const originalHeight = element.style.height;
  element.style.height = 'auto'; // Or you can set it to a specific height

  const pdf = new jsPDF('p', 'mm', 'a4');
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  const margin = 10; // Margin for the PDF content
  let y = margin; // Y-axis position for the PDF content

  // Capture the HTML element as canvas
  const canvas = await html2canvas(element, { scale: 2 });
  const imgData = canvas.toDataURL('image/png');

  const imgWidth = pdfWidth - margin * 2;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;

  // Add content to PDF, splitting across pages if needed
  while (y + imgHeight > pdfHeight - margin) {
    const remainingHeight = pdfHeight - y - margin;
    const canvasSlice = document.createElement('canvas');
    const context = canvasSlice.getContext('2d');

    canvasSlice.width = canvas.width;
    canvasSlice.height = (canvas.height * remainingHeight) / imgHeight;

    context.drawImage(
      canvas,
      0, (canvas.height * (y - margin)) / imgHeight,
      canvas.width, canvasSlice.height,
      0, 0,
      canvasSlice.width, canvasSlice.height
    );

    const sliceImgData = canvasSlice.toDataURL('image/png');
    pdf.addImage(sliceImgData, 'PNG', margin, margin, imgWidth, remainingHeight);

    pdf.addPage();
    y = margin; // Reset Y for new page
  }

  // Add final portion if there’s space left
  pdf.addImage(imgData, 'PNG', margin, y, imgWidth, imgHeight - y);

  // Reset the overflow style
  element.style.overflow = originalStyle;
  element.style.height = originalHeight; // Reset the height

  pdf.save('Invoice.pdf');
};

  

  

  const reset = () => {
    setShowTemplate(false);
    setShowPoForm(false);
  };

  const formatCurrency = (value) =>
    typeof value === 'number' ? `₹${value.toFixed(2)}` : '₹0.00';

  return (
    showTemplate && Data && (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center p-4">
        <div
          className="bg-white p-10 rounded-lg shadow-lg w-full max-w-4xl "
          ref={templateRef}
        >
          {/* Close Button */}
          <button
            onClick={reset}
            className="absolute top-4 right-4 text-red-500 hover:text-red-800"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>

          {/* Header */}
          <div className="flex items-center justify-between mb-6 border-b pb-4">
            <img src={logo} alt="Logo" className="h-16 w-16 object-contain" />
            <h1 className="text-3xl font-extrabold text-gray-800">Invoice</h1>
          </div>

          {/* Billing and Shipping Details */}
          <div className="grid grid-cols-2 gap-6 mb-6">
            <div className="text-sm">
              <h3 className="font-semibold text-gray-700 mb-2">Bill To:</h3>
              <p><FontAwesomeIcon icon={faUser} /> {Data.billTo.name}</p>
              <p><FontAwesomeIcon icon={faMapMarkerAlt} /> {Data.billTo.address}</p>
              <p><FontAwesomeIcon icon={faEnvelope} /> {Data.billTo.email}</p>
              <p><FontAwesomeIcon icon={faReceipt} /> {Data.billTo.gst}</p>
            </div>
            <div className="text-sm">
              <h3 className="font-semibold text-gray-700 mb-2">Ship To:</h3>
              <p><FontAwesomeIcon icon={faUser} /> {Data.shipTo.name}</p>
              <p><FontAwesomeIcon icon={faMapMarkerAlt} /> {Data.shipTo.address}</p>
              <p><FontAwesomeIcon icon={faEnvelope} /> {Data.shipTo.email}</p>
              <p><FontAwesomeIcon icon={faReceipt} /> {Data.shipTo.gst}</p>
            </div>
          </div>

          {/* Order Table */}
          <div className="overflow-x-auto max-h-64">
  <table className="w-full text-sm border-collapse border border-gray-300">
    <thead className="bg-teal-600 text-white sticky top-0">
      <tr>
        <th className="border px-4 py-2">Description</th>
        <th className="border px-4 py-2">HSN</th>
        <th className="border px-4 py-2">Qty</th>
        <th className="border px-4 py-2">UOM</th>
        <th className="border px-4 py-2">Rate</th>
        <th className="border px-4 py-2">Tax %</th>
        <th className="border px-4 py-2">Amount</th>
      </tr>
    </thead>
    <tbody>
      {Data.orderItems.map((item, index) => (
        <tr key={index}>
          <td className="border px-4 py-2">{item.description}</td>
          <td className="border px-4 py-2">{item.hsnCode}</td>
          <td className="border px-4 py-2">{item.qty}</td>
          <td className="border px-4 py-2">{item.uom}</td>
          <td className="border px-4 py-2">{formatCurrency(item.itemRate)}</td>
          <td className="border px-4 py-2">{item.taxPercent || 0}</td>
          <td className="border px-4 py-2">
            {formatCurrency(item.qty * item.itemRate)}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>


        {/* Summary Section */}
<div className="grid grid-cols-2 gap-4 text-sm border-t-2 border-gray-300 pt-4">
  <div className="space-y-2">
    <div className="flex justify-between">
      <span className="font-semibold">Total Qty:</span>
      <span>{Data.totalQty}</span>
    </div>
    <div className="flex justify-between">
      <span className="font-semibold">Total Amount:</span>
      <span>{formatCurrency(Data.totalAmount)}</span>
    </div>
    <div className="flex justify-between">
      <span className="font-semibold">Total Tax:</span>
      <span>{formatCurrency(Data.totalTax)}</span>
    </div>
  </div>

  <div className="flex items-end justify-between border-l-2 pl-4">
    <span className="text-lg font-bold">Grand Total:</span>
    <span className="text-lg font-bold text-green-600">
      {formatCurrency(Data.orderTotal)}
    </span>
  </div>
</div>


            </div>

        <div className=" absolute top-4 left-4">
            <button
              onClick={handleDownload}
              className="bg-green-600 hover:bg-green-700 text-white px-2 py-1 rounded-md text-sm focus:outline-none"
            >
              <FontAwesomeIcon icon={faDownload} className="" />
            </button>
          </div>
      </div>
    )
  );
};

export default PurchaseTemplate;
