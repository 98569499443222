import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faClipboardList ,faCubes} from '@fortawesome/free-solid-svg-icons';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import BarrelStockTable from './Barreltable';
import MiscellaneousExpensesTable from './MeTable';

const TwostockModal = ({ Onclose }) => {
  const [isStockBTOpen, setIsStockBTOpen] = useState(false);
  const [meTableOpen, setMeTableOpen] = useState(false);

  const BarrelTableOpen = () => {
    setIsStockBTOpen(true);
  };

  const BarrelTableClose = () => {
    setIsStockBTOpen(false);
  };
  const MeTableOpen = () => setMeTableOpen(true);
  const MeTabledown = () => setMeTableOpen(false);
  return (
    <>
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-lg p-6 relative">
        {/* Modal Header */}
        <div className="flex justify-between items-center border-b pb-2">
          <h1 className="text-xl font-semibold text-gray-700">Select</h1>
          <button
            onClick={Onclose}
            className="text-gray-400 hover:text-red-500 transition duration-200"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
        </div>

        {/* Buttons Section */}
        <div className="mt-6 grid grid-cols-1 gap-4">
          <button
            className="flex items-center justify-center w-full px-4 py-3 text-sm font-medium bg-green-100 text-green-800 rounded-lg hover:bg-green-200 transition duration-200"
            onClick={MeTableOpen}
          >
            <FaMoneyCheckAlt size={20} className="mr-2" />
            Miscellaneous Stock
          </button>
          <button
            onClick={BarrelTableOpen}
            className="flex items-center justify-center w-full px-4 py-3 text-sm font-medium bg-yellow-100 text-yellow-800 rounded-lg hover:bg-yellow-200 transition duration-200"
          >
            <FontAwesomeIcon icon={faCubes} size="lg" className="mr-2" />
            View Barrel Stock
          </button>
        </div>
      </div>

 
    </div>
         {/* Barrel Stock Table Modal */}
         {isStockBTOpen && <BarrelStockTable Onclose={BarrelTableClose} />}
         {meTableOpen && <MiscellaneousExpensesTable Onclose={MeTabledown} />}
         </>
  );
};

export default TwostockModal;
