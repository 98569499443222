import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ShowTable = ({ closeTable }) => {

  const [logsheetData, setLogsheetData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [batchOptions, setBatchOptions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [batchNo, setBatchNo] = useState('');
  const handleClear = () => {
    setBatchNo(''); // Reset the selected batch
    setLogsheetData([]); // Clear the logsheet data
    setIsSubmitted(false); // Reset the submission state
    console.log('Batch number reset:', batchNo); // Debugging log
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/logsheet/all-data`);
        const data = response.data;

        const uniqueBatchNos = [...new Set(data.map(item => item.batchNo))];
        const options = uniqueBatchNos.map(batch => ({ value: batch, label: batch }));
        setBatchOptions(options);

        setAllData(data);
        console.log('showTable', data);
        
      } catch (error) {
        console.error('Error fetching logsheet data:', error);
        toast.error('Failed to load logsheet data.');
      }
    };

    fetchAllData();
  }, []);

  const handleSubmit = () => {
    if (!batchNo) {
      toast.error('Please select a batch number.');
      return;
    }

    setIsSubmitted(true);

    const filteredData = allData.filter(item => item.batchNo === batchNo);
    setLogsheetData(filteredData);
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-gray-800 text-white p-4 rounded-2xl shadow-lg max-w-full w-full h-full relative">
        <button onClick={closeTable} className="absolute top-4 right-4 text-red-500">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="text-2xl font-semibold mb-6 pb-2">Logsheet History</h2>

        <div className="flex items-center mb-6 relative">
          <Select
            placeholder="Select Batch No"
            value={batchNo ? batchOptions.find(option => option.value === batchNo) : null}
            onChange={(selectedOption) => setBatchNo(selectedOption ? selectedOption.value : '')}
            options={batchOptions}
            className="w-1/2 text-sm"
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: 'transparent',
                borderColor: 'gray',
                boxShadow: 'none',
              }),
              menu: (provided) => ({
                ...provided,
                maxHeight: '200px',
                overflowY: 'auto',
                marginTop: 0,
              }),
              menuList: (provided) => ({
                ...provided,
                padding: 0,
                maxHeight: '200px',
                overflowY: 'auto',
              }),
              singleValue: (provided) => ({
                ...provided,
                color: 'white',
              }),
              option: (provided, state) => ({
                ...provided,
                color: 'black',
                cursor: 'pointer',
              }),
              placeholder: (provided) => ({
                ...provided,
                color: 'white',
              }),
            }}
          />

          <button
            onClick={handleSubmit}
            className="ml-8 w-20 bg-rose-500 text-sm text-white px-3 py-1.5 rounded-lg hover:bg-rose-600"
          >
            Submit
          </button>
          <button
          onClick={handleClear}
          className="ml-8  w-20 bg-gray-300 text-gray-700 px-3 py-1.5 rounded-lg text-sm hover:bg-gray-400"
        >
          Clear
        </button>
        </div>

        <div className="max-h-[calc(100vh-180px)] overflow-y-auto">
          <table className="table-auto w-full bg-gray-700 text-white border-collapse">
            <thead className="table-header bg-teal-500 text-white text-xs sticky top-0">
              <tr>
                <th rowSpan="2" className="px-2 py-1 border border-gray-500">Batch No</th>
                <th rowSpan="2" className="px-2 py-1 border border-gray-500">Time</th>
                <th rowSpan="2" className="px-2 py-1 border border-gray-500">Material</th>
                <th rowSpan="2" className="px-2 py-1 border border-gray-500">Feed Qty (KL)</th>
                <th rowSpan="2" className="px-2 py-1 border border-gray-500">From Tank</th>
                <th className="px-2 py-1 border border-gray-500" colSpan="2">EB Reading (Kwh)</th>
                <th className="px-2 py-1 border border-gray-500" colSpan="2">LDO (KL)</th>
                <th rowSpan="2" className="px-2 py-1 border border-gray-500">Reactor</th>
                <th className="px-2 py-1 border border-gray-500" colSpan="2">Reactor Temp (°C)</th>
                <th rowSpan="2" className="px-2 py-1 border border-gray-500">Reactor Pressure</th>
                <th rowSpan="2" className="px-2 py-1 border border-gray-500">Receiver Pressure</th>
                <th rowSpan="2" className="px-2 py-1 border border-gray-500">Circulating Pump Pressure</th>
                <th className="px-2 py-1 border border-gray-500" colSpan="2">Boiler Temp (°C)</th>
                <th className="px-2 py-1 border border-gray-500" colSpan="2">Water Temp (°C)</th>
                <th rowSpan="2" className="px-2 py-1 border border-gray-500">Others</th>
                <th className="px-2 py-1 border border-gray-500" colSpan="3">Storage Tank</th>
                <th rowSpan="2" className="px-2 py-1 border border-gray-500">No. of Operators</th>
                <th rowSpan="2" className="px-2 py-1 border border-gray-500">Operator Name</th>
                <th rowSpan="2" className="px-2 py-1 border border-gray-500">Remarks</th>
              </tr>
              <tr>
                <th className="px-2 py-1 border border-gray-500">Open</th>
                <th className="px-2 py-1 border border-gray-500">Close</th>
                <th className="px-2 py-1 border border-gray-500">Open</th>
                <th className="px-2 py-1 border border-gray-500">Close</th>
                <th className="px-2 py-1 border border-gray-500">Open</th>
                <th className="px-2 py-1 border border-gray-500">Close</th>
                <th className="px-2 py-1 border border-gray-500">Open</th>
                <th className="px-2 py-1 border border-gray-500">Close</th>
                <th className="px-2 py-1 border border-gray-500">Open</th>
                <th className="px-2 py-1 border border-gray-500">Close</th>
                <th className="px-2 py-1 border border-gray-500">Product</th>
                <th className="px-2 py-1 border border-gray-500">Qty (KL)</th>
                <th className="px-2 py-1 border border-gray-500">Tank</th>
              </tr>
            </thead>
            <tbody>
              {isSubmitted ? (
                logsheetData.length === 0 ? (
                  <tr>
                    <td colSpan="27" className="text-center py-4 text-gray-200">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  logsheetData.map((item, index) => (
                    <tr key={index} className='text-xs'>
                      <td className="px-2 py-2 border border-gray-600 whitespace-nowrap">{item.batchNo || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600 whitespace-nowrap">{new Date(item.Time).toLocaleString() || 'N/A'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.Material || 'N/A'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.Qty?.toFixed(2) || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.From || 'N/A'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.EbOpening || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.EbClosing || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.LdoOpening || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.LdoClosing || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.Reactor || 'N/A'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.ReactorTempOpening || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.ReactorTempClosing || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.ReactorPressure || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.ReceiverPressure || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.CirculatingPumpPressure || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.BoilerOpening || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.BoilerClosing || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.WaterOpening || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.WaterClosing || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.Others || 'N/A'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.Product || 'N/A'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.Pqty?.toFixed(2) || '0.00'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.Ptank || 'N/A'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.NoOfOperators || '0'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.OperatorName || '0'}</td>
                      <td className="px-2 py-2 border border-gray-600">{item.Remarks || 'N/A'}</td>
                    </tr>
                  ))
                )
              ) : (
                <tr>
                  <td colSpan="27" className="text-center py-4 text-gray-200">
                    Please select a batch number and click Submit.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ShowTable;
