import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { FaTimes, FaList } from 'react-icons/fa';


const formatDecimal = (value) => {
  if (value === null || value === undefined) return '0.00';
  return parseFloat(value).toFixed(2);
};

const mapRow = (dbRow) => {
  const mappedRow = {
    factoryname: dbRow.factoryname,
    batchNo: dbRow.batchNo,
    id: dbRow.id,
    time: dbRow.Time,
    material: dbRow.Material,
    qty: formatDecimal(dbRow.Qty),
    from: dbRow.From,
    ebOpening: formatDecimal(dbRow.EbOpening),
    ebClosing: formatDecimal(dbRow.EbClosing),
    ldoOpening: formatDecimal(dbRow.LdoOpening),
    ldoClosing: formatDecimal(dbRow.LdoClosing),
    reactor: dbRow.Reactor,
    reactorTempOpening: dbRow.ReactorTempOpening,
    reactorTempClosing: dbRow.ReactorTempClosing,
    reactorPressure: dbRow.ReactorPressure,
    receiverPressure: dbRow.ReceiverPressure,
    circulatingPumpPressure: dbRow.CirculatingPumpPressure,
    boilerOpening: dbRow.BoilerOpening,
    boilerClosing: dbRow.BoilerClosing,
    waterOpening: dbRow.WaterOpening,
    waterClosing: dbRow.WaterClosing,
    Others: dbRow.Others,
    product: dbRow.Product,
    pqty: formatDecimal(dbRow.Pqty),
    ptank: dbRow.Ptank,
    noOfOperators: dbRow.NoOfOperators,
    operatorName: dbRow.OperatorName,
    remarks: dbRow.Remarks,
    editable: true,
  };

  console.log("Mapped Row:", mappedRow); // Log the mapped row values

  return mappedRow;
};


const ModalComponent = ({ updateRow,setSummary,setIstrue,showModal,setShowModal,initialRow, setRows, setFactoryname, setIsFactoryNameSetByModal,setBatchNo}) => {
  const [savedRows, setSavedRows] = useState([]);
  const [logsheetBatches, setLogsheetBatches] = useState(new Set());

  const [uniqueBatchNos, setUniqueBatchNos] = useState([]);
  const [selectedBatchNos, setSelectedBatchNos] = useState(new Set());


  useEffect(() => {
    if (showModal) {
      axios.get(`${config.apiUrl}/saverows/saved-rows`)
        .then(response => {
          if (Array.isArray(response.data)) {
            const batchNoMap = {};
            response.data.forEach(row => {
              if (!batchNoMap[row.batchNo]) {
                batchNoMap[row.batchNo] = [];
              }
              batchNoMap[row.batchNo].push(row);
            });

            const uniqueBatchNos = Object.keys(batchNoMap);
            setSavedRows(response.data);
            setUniqueBatchNos(uniqueBatchNos);
          } else {
            console.error('Expected an array but got:', response.data);
            setSavedRows([]);
            setUniqueBatchNos([]);
          }
        })
        .catch(error => {
          console.error('Error fetching saved rows:', error);
          setSavedRows([]);
          setUniqueBatchNos([]);
        });
        // Fetch logsheet data
      axios.get(`${config.apiUrl}/logsheet/all-data`)
      .then(response => {
        if (Array.isArray(response.data)) {
          const logsheetBatchNos = new Set(response.data.map(row => row.batchNo));
          setLogsheetBatches(logsheetBatchNos);
        } else {
          console.error('Expected an array but got:', response.data);
          setLogsheetBatches(new Set());
        }
      })
      .catch(error => {
        console.error('Error fetching logsheet data:', error);
        setLogsheetBatches(new Set());
      });
  
    }
  }, [showModal]);

  const handleCheckboxClick = (batchNo) => {
    // Toggle checkbox selection
    setSelectedBatchNos(prevSelectedBatchNos => {
      const newSelectedBatchNos = new Set(prevSelectedBatchNos);
  
      if (newSelectedBatchNos.has(batchNo)) {
        newSelectedBatchNos.delete(batchNo); // Uncheck if already checked
      } else {
        newSelectedBatchNos.add(batchNo); // Check if not already checked
      }
  
      // Convert Set to Array for further processing
      const selectedBatches = Array.from(newSelectedBatchNos);
  
      // Filter and map selected rows
      const selectedRows = savedRows
        .filter(row => selectedBatches.includes(row.batchNo))
        .map(mapRow);
  
      // Fetch and process data for the selected batch number
      if (selectedBatches.length > 0) {
        axios.get(`${config.apiUrl}/summary/${batchNo}`)
          .then(response => {
            const { ebUsage, ldoUsage, summaryData  } = response.data;
            console.log('summary', response.data);
  
           // Update the summary state with the new data
      setSummary(prevSummary => {
        const updatedSummaryData = (summaryData || []).map(item => ({
          ...item,
          solvent: {
            name: item.solvent.name ,
            qty: item.solvent.qty 
          },
          finishProduct: {
            name: item.finishProduct.name ,
            qty: item.finishProduct.qty
          },
          condensateQty: item.condensateQty,
          residueQty: item.residueQty,
          editable: false 
        }));

        return {
          ebUsage: ebUsage || 0,
          ldoUsage: ldoUsage || 0,
          summaryData: updatedSummaryData
        };
      });
  
            // Update rows with selected rows
            if (selectedRows.length === 0) {
              setRows(Array(12).fill(initialRow)); // Reset to initial rows
              setFactoryname('Avatar'); // Reset to default factory name
              setIsFactoryNameSetByModal(false); 
              setBatchNo(''); // Reset batch number in CustomDatePicker
            } else {
              updateRow(selectedRows);
              setIsFactoryNameSetByModal(true); 
              setBatchNo(selectedBatches[0]); // Update batch number in CustomDatePicker
            }
  
            // Close the modal after processing
            setShowModal(false);
  
            // Clear the selection after processing
            setSelectedBatchNos(new Set());
          })
          .catch(error => {
            console.error('Error fetching summary data:', error);
  
            // Handle error by resetting summary data
      setSummary(prevSummary => ({
        ...prevSummary,
        ebUsage: 0,
        ldoUsage: 0,
        summaryData: []
      }));
  
            // Reset rows and batch info
            if (selectedRows.length === 0) {
              setRows(Array(12).fill(initialRow));
              setFactoryname('Avatar');
              setIsFactoryNameSetByModal(false); 
              setBatchNo('');
            } else {
              updateRow(selectedRows);
              setIsFactoryNameSetByModal(true); 
              setBatchNo(selectedBatches[0]);
            }
  
            // Close the modal after error handling
            setShowModal(false);
  
            // Clear the selection after processing
            setSelectedBatchNos(new Set());
          });
      } else {
        // If no batches are selected, reset state
        setRows(Array(12).fill(initialRow));
        setFactoryname('Avatar');
        setIsFactoryNameSetByModal(false); 
        setBatchNo('');
        setSelectedBatchNos(new Set());
      }
  
      // Return new selected batch numbers
      return newSelectedBatchNos;
    });
  };
  
  
  

  return (
    <div>
      <div className="absolute top-2 right-20"></div>
  
      {showModal && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-700 p-4 text-white rounded-xl shadow-lg w-[50vw] h-[60vh] relative">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
              onClick={() => setShowModal(false)}
            >
              <FaTimes className="h-5 w-5 text-red-500" />
            </button>
            <h3 className="text-xl font-semibold mb-4">Saved Rows</h3>
            <div className="overflow-x-auto h-[calc(100%-3rem)]">
              <table className="min-w-full bg-gray-700">
                <thead className="bg-teal-500 text-white sticky top-0">
                  <tr>
                    <th className="border border-gray-400 p-2 text-left text-sm font-medium">S.No</th>
                    <th className="border border-gray-400 p-2 text-left text-sm font-medium">Batch No</th>
                    <th className="border border-gray-400 p-2 text-left text-sm font-medium">Select</th>
                  </tr>
                </thead>
                <tbody>
                  {uniqueBatchNos.length > 0 ? (
                    uniqueBatchNos.map((batchNo, index) => (
                      <tr key={batchNo}>
                        <td className="border border-gray-500 p-2 text-sm">{index + 1}</td>
                        <td className="border border-gray-500 p-2 text-sm">
                          <span
                            className={
                              logsheetBatches.has(batchNo)
                                ? 'text-red-400'
                                : 'text-green-400'
                            }
                          >
                            {batchNo}
                          </span>
                        </td>
                        <td className="border border-gray-500 p-2 text-sm">
                          <input
                            type="checkbox"
                            checked={selectedBatchNos.has(batchNo)}
                            onChange={() => handleCheckboxClick(batchNo)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={3}
                        className="border border-gray-500 p-4 text-center text-sm text-gray-400"
                      >
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  
};

export default ModalComponent;
