import React, { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { faTimes, faSearch, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import BarreltoTank from './BarreltoTank'; // Import TransferModal
import EditStockModal from './EditBarrelstock'; // Create and import an Edit Stock Modal
import axios from 'axios';
import config from '../config';  // Assuming config is in the same folder

const BarrelStockTable = ({ Onclose, loading}) => {
    const [barrelStock, setBarrelStock] = useState([]);

    useEffect(() => {
        fetchBarrelStock();
      }, []);

    const fetchBarrelStock = async () => {
        const response = await axios.get(`${config.apiUrl}/stock/get-barrel-stock-details`);
        setBarrelStock(response.data);
    };
    
    const [filter, setFilter] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStock, setSelectedStock] = useState(null); // Store selected row data
    const [isTransferModalOpen, setTransferModalOpen] = useState(false); // Modal visibility
    const [isEditModalOpen, setEditModalOpen] = useState(false); 

    const filteredStock = barrelStock.filter(stock =>
        stock.Material.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleClearSearch = () => {
        setFilter('');
        setSearchQuery('');
    };

    const handleSearch = () => {
        setSearchQuery(filter);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleOpenTransferModal = (stock) => {
        setSelectedStock(stock); // Set the selected row data
        setTransferModalOpen(true); // Open the modal
    };

    const handleCloseTransferModal = () => {
        setTransferModalOpen(false);
        setSelectedStock(null); // Clear selected stock data
        fetchBarrelStock();
    };

    const handleOpenEditModal = (stock) => {
        setSelectedStock(stock); // Set the selected row data
        setEditModalOpen(true); // Open the edit modal
    };

    const handleCloseModals = () => {
        setTransferModalOpen(false);
        setEditModalOpen(false);
        setSelectedStock(null); // Clear selected stock data
        fetchBarrelStock();
    };

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-gray-800 p-6 rounded-2xl shadow-md max-w-full w-full h-full flex flex-col relative">
                <button onClick={Onclose} className="absolute top-2 right-2">
                    <FontAwesomeIcon className="text-red-500" icon={faTimes} />
                </button>
                <h3 className="text-lg font-semibold mb-2 text-center text-white">Barrel Stock</h3>
                <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center space-x-4">
                        <input
                            type="text"
                            placeholder="Filter by Material..."
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            className="border border-gray-600 bg-transparent p-1 w-40 text-white rounded-lg"
                        />
                        <button
                            onClick={handleSearch}
                            className="bg-blue-600 text-white px-3 py-1 rounded-lg hover:bg-blue-700 transition-colors"
                        >
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                        <button
                            onClick={handleClearSearch}
                            className="bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600 transition-colors"
                        >
                            Clear
                        </button>
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full bg-gray-700 border border-gray-500 max-h-[calc(100vh-188px)] overflow-y-auto">
                        <thead className="bg-teal-600 text-white sticky top-0 text-xs text-center">
                            <tr>
                                <th className="py-2 px-4">Material</th>
                                <th className="py-2 px-4">Barrel Count</th>
                                <th className="py-2 px-4">Litres/Barrel</th>
                                <th className="py-2 px-4">QTY  (KL) </th>
                                <th className="py-2 px-4">Yard</th>
                                <th className="py-2 px-4">Date</th>
                                <th className="py-2 px-4">Edit</th>
                                <th className="py-2 px-4">Transfer</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="7" className="py-4 text-center text-white">
                                        Loading...
                                    </td>
                                </tr>
                            ) : filteredStock.length ? (
                                filteredStock.map(stock => (
                                    <tr key={stock.id} className="text-xs text-center border-b font-semibold">
                                        <td className="py-2 px-4">{stock.Material}</td>
                                        <td className="py-2 px-4">{stock.barrelCount}</td>
                                        <td className="py-2 px-4">{stock.Litres_Per_Barrel.toFixed(2)}</td>
                                        <td className="py-2 px-4">{stock.qty.toFixed(2)}</td>
                                        <td className="py-2 px-4">{stock.yard}</td>
                                        <td className="py-2 px-4">{formatDate(stock.Date)}</td>
                                        <td className="py-2 px-4">
                                            <button
                                                onClick={() => handleOpenEditModal(stock)}
                                                className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
                                            >
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                        </td>
                                        <td className="py-2 px-4">
                                            <button
                                                onClick={() => handleOpenTransferModal(stock)}
                                                className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
                                            >
                                                <FontAwesomeIcon icon={faExchangeAlt} />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="py-4 text-center text-white text-xs">
                                        No Data Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {isTransferModalOpen && (
                <BarreltoTank
                    barrelId={selectedStock.id}
                    material={selectedStock.Material}
                    qty={selectedStock.qty}
                    yard={selectedStock.yard}
                    onTransferComplete={handleCloseTransferModal}
                    onClose={handleCloseTransferModal}
                    fetchBarrelStock={fetchBarrelStock}
                />
            )}

            {isEditModalOpen &&  (
                <EditStockModal
                barrelId={selectedStock.id}
                yard={selectedStock.yard}
                date={selectedStock.Date}
                onClose={handleCloseModals}
                fetchBarrelStock={fetchBarrelStock}
                />
            )}
        </div>
    );
};

export default BarrelStockTable;
