import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPlus } from 'react-icons/fa'; 
import ColorModal from './ColorModal';
import { HiInformationCircle } from "react-icons/hi";
import { HiOutlineSave } from 'react-icons/hi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import InoutHistory from './InoutHistory';
import SaveModal from './SaveModal';

const InWardForm = () => {
    const [isOutWard, setIsOutWard] = useState(false);
    const [tankOptions, setTankOptions] = useState([]);
    const [formData, setFormData] = useState({
        purchaseOrderNo: '',
        invoiceNo: '',
        ewayBillNo: '',
        date: '',
        vehicleNo: '',
        driverName: '',
        inandout: '',
        location: '',
        uom: '',
        color: '',
        manifestNo:'',
        schedule:'',
        pollutionboard:'',
        remarks: '',
        materialRows: [
            {
              tankNo: '',
              fuelName: '',
              hsnCode: '',
              grossWeight: '',
              tareWeight: '',
              netWeight: '',
              density: '',
              barrelCount :'',
             litresPerBarrel :"" ,
             manualNetWeight: false,
            },
          ],
 });
    const [customColors, setCustomColors] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [istableOpen, setIstableOpen] = useState(false);
    const [materialOptions, setMaterialOptions] = useState([]);
    const [isManualNetWeight, setIsManualNetWeight] = useState(false); 
    const [isSaving, setIsSaving] = useState(true);
    
    const [isSubmit, setIsSubmit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedDataType, setSelectedDataType] = useState('inward');
    const handleSaveData = (updatedData) => {
        setFormData(updatedData);
        closeModal(); 
      };
    
    useEffect(() => {
        const fetchColors = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/in_out/colors`);
                setCustomColors(response.data.map(color => color.name));
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };
 fetchColors();
    }, []);
    useEffect(() => {
        console.log("isSaving:", isSaving); // Debug log
      }, [isSaving]);
      
            
    useEffect(() => {
        const fetchOilNames = async () => {
          try {
            const response = await axios.get(`${config.apiUrl}/stock/getoilnames`);
            const options = response.data.map(item => ({
              value: item.oilname,
              label: item.oilname,
            }));
            setMaterialOptions(options);
          } catch (error) {
            console.error('Failed to fetch oil names:', error);
          }
        };
    
        fetchOilNames();
      }, []);
    
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);
    const handleAddColor = (newColor) => {
        setCustomColors(prevColors => [...prevColors, newColor.name]);
    };
    const validateVehicleNo = (vehicleNo) => {
        const vehicleNoPattern = /^[A-Z]{2}\s\d{2}\s[A-Z]{1,2}\s\d{4}$/;
        return vehicleNoPattern.test(vehicleNo);
    };
    const fetchTankOptions = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/stock/getTanks`);
            if (response.data && Array.isArray(response.data.data)) {
                const formattedOptions = response.data.data.map(tank => ({
                    label: tank.tankname,
                    value: tank.tankname,
                    tankradius: tank.tankradius
                }));
                setTankOptions(formattedOptions);
                  } else {
                console.error('Unexpected response format:', response.data);
            }
        } catch (error) {
            console.error('Error fetching tank options:', error);
        }
    };
    
    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
setIsOutWard(isChecked);
setFormData(prevData => {
           return {
                ...prevData,
                isOutWard: isChecked,
                netWeight: '',
                grossWeight:'',
                tareWeight:'',
                density:'',
                barrelCount: '', 
                litresPerBarrel: '',
 };
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
          // Log the field being changed and its value
  console.log(`Field: ${name}, Value: ${value}`);
        setFormData(prevData => {
            let newValue = parseFloat(value) || 0;
        if (isManualNetWeight) {
            return {
                ...prevData,
                [name]: value,
                grossWeight: 0,   
                tareWeight: 0  ,
            };
        }
            let newGrossWeight = parseFloat(prevData.grossWeight) || 0;
            let newTareWeight = parseFloat(prevData.tareWeight) || 0;
            let newDensity = parseFloat(prevData.density) || 0;
            let newBarrelCount = parseInt(prevData.barrelCount, 10) || 1;
            let newLitresPerBarrel = parseFloat(prevData.litresPerBarrel) || 0;
            let newNetWeight = 0; 
            if (name === 'tankNo' && isSaving ) {
                return {
                    ...prevData,
                    [name]: value,
                    grossWeight: '',
                    tareWeight: '',
                    netWeight: ''
                };
            } else if (name === 'tankNo' && !isSaving) {
            return {
                ...prevData,
                [name]: value
            };
        }
            switch (name) {
                case 'grossWeight':
                    newGrossWeight = newValue;
                    break;
                case 'tareWeight':
                    newTareWeight = newValue;
                    break;
                case 'density':
                    newDensity = newValue;
                    break;
                case 'barrelCount':
                    newBarrelCount = newValue;
                    break;
                case 'litresPerBarrel':
                    newLitresPerBarrel = newValue;
                    break;
                case 'tankNo':
                    const selectedTank = tankOptions.find(tank => tank.tankname === value);
                    const radius = selectedTank ? selectedTank.tankradius : 0;
                    if (radius && !isNaN(radius)) {
                        newNetWeight = (parseFloat(prevData.netWeight) || 0) * radius * radius * Math.PI / 4;
                    }
                    break;
                default:
                    break;
            }
    
            // Validate that both weights are non-negative
            if (newGrossWeight < 0 || newTareWeight < 0) {
                return {
                    ...prevData,
                    [name]: value,
                    netWeight: '' // Reset net weight if any input is invalid
                };
            }
    
            // Validate that Tare Weight is not greater than Gross Weight
            if (newTareWeight > newGrossWeight) {
                return {
                    ...prevData,
                    [name]: value,
                    netWeight: '',
                    tareWeight: '',
                    grossWeight: ''
                };
            }
    
            if (!isManualNetWeight && newGrossWeight >= 0 && newTareWeight >= 0) {
                newNetWeight = newGrossWeight - newTareWeight; // Calculate netWeight if not manual
            }
    
            // Apply UOM-based logic for converting to KL
            switch (prevData.uom) {
                case 'MTS':
                    newNetWeight = prevData.isOutWard
                        ? (newNetWeight * newDensity)
                        : (newNetWeight / newDensity)
                    break;
                case 'Barrel':
                    const klPerBarrel = newLitresPerBarrel / 1000;
                    newNetWeight = klPerBarrel * newBarrelCount;
                    break;
                case 'Litre':
                    newNetWeight = newNetWeight / 1000;
                    break;
                case 'Kgs':
                    newNetWeight = (newNetWeight / (newDensity * 1000))
                    break;
                    case 'cm':
                        const tank = tankOptions.find(t => t.tankname === prevData.tankNo);

                        if (tank) {
                            newNetWeight = newNetWeight * tank.tankradius * tank.tankradius * 3.14 / 4;
                                                       }
                        break;
                default:
                    break;
            }
            // Ensure newNetWeight is a valid number before using .toFixed()
            newNetWeight = isNaN(newNetWeight) ? 0 : parseFloat(newNetWeight).toFixed(3)
            return {
                ...prevData,
                [name]: value,
                netWeight: isManualNetWeight ? prevData.netWeight : newNetWeight
            };
        });
    };

     // Handle dynamic row changes
     const handleRowChange = (index, event) => {
        const { name, value } = event.target;
        const updatedRows = [...formData.materialRows];
    
         // Log initial updated row before processing
    console.log("Updated Row (before calculation):", updatedRows[index]);
    
 // Update the specific field in the materialRows array (before calculations)
 updatedRows[index] = { ...updatedRows[index], [name]: value };
    
        // Extract and parse the values
    let newGrossWeight = parseFloat(updatedRows[index].grossWeight) || 0;
    let newTareWeight = parseFloat(updatedRows[index].tareWeight) || 0;
    let newDensity = parseFloat(updatedRows[index].density) || 0;
    let newBarrelCount = parseInt(updatedRows[index].barrelCount, 10) || 0;
    let newLitresPerBarrel = parseFloat(updatedRows[index].litresPerBarrel) || 0;
    let newNetWeight = 0;
    
        // Update based on the field name to handle specific cases
        switch (name) {
            case 'grossWeight':
                newGrossWeight = parseFloat(value) || 0;
                break;
            case 'tareWeight':
                newTareWeight = parseFloat(value) || 0;
                break;
            case 'density':
                newDensity = parseFloat(value) || 0;
                break;
            case 'barrelCount':
                newBarrelCount = parseInt(value, 10) || 0;
                break;
            case 'litresPerBarrel':
                newLitresPerBarrel = parseFloat(value) || 0;
                break;
            case 'tankNo':
                // Assuming tankOptions is defined and accessible
                const selectedTank = tankOptions.find(tank => tank.tankname === value);
                const radius = selectedTank ? selectedTank.tankradius : 0;
                if (radius && !isNaN(radius)) {
                    newNetWeight = (newGrossWeight - newTareWeight) * radius * radius * Math.PI / 4;
                }
                break;
            default:
                break;
        }
     // Log calculations for specific cases
     console.log("Calculated Net Weight after switch:", newNetWeight);
        // Validate weights
        if (newGrossWeight < 0 || newTareWeight < 0) {
            newNetWeight = ''; // Reset net weight if any input is invalid
        }
    
        if (newTareWeight > newGrossWeight) {
            newNetWeight = '';
            newTareWeight = '';
            newGrossWeight = '';
        }
    
         // If manual net weight is enabled, just use the entered value for netWeight
    // If manual net weight is enabled, use the entered value
    if (updatedRows[index].isManualNetWeight) {
        newNetWeight = updatedRows[index].netWeight || '';
    } else if (!updatedRows[index].isManualNetWeight && newGrossWeight >= 0 && newTareWeight >= 0) {
        newNetWeight = newGrossWeight - newTareWeight;
    }
    
    switch (formData.uom) {
            case 'MTS':
                newNetWeight = updatedRows[index].isOutWard 
                    ? (newNetWeight * newDensity)
                    : (newNetWeight / newDensity);
                break;
            case 'Barrel':
                const klPerBarrel = newLitresPerBarrel / 1000;
                newNetWeight = klPerBarrel * newBarrelCount;
                break;
            case 'Litre':
                newNetWeight = newNetWeight / 1000;
                break;
            case 'Kgs':
                newNetWeight = (newNetWeight / (newDensity * 1000));
                break;
            case 'cm':
                const tank = tankOptions.find(t => t.tankname ===  updatedRows[index].tankNo);
                if (tank) {
                    newNetWeight = newNetWeight * tank.tankradius * tank.tankradius * Math.PI / 4;
                }
                break;
            default:
                break;
        }
   // Log the UOM-based calculation for net weight
   console.log("Final Net Weight after UOM-based logic:", newNetWeight);

   // Ensure newNetWeight is a valid number before using .toFixed()
   newNetWeight = isNaN(newNetWeight) ? 0 : parseFloat(newNetWeight).toFixed(3);
     // Log final updated row
     console.log("Updated Row (after processing):", {
        ...updatedRows[index],
        grossWeight: newGrossWeight,
        tareWeight: newTareWeight,
        netWeight: updatedRows[index].isManualNetWeight ? updatedRows[index].netWeight : newNetWeight,
        density: newDensity,
        barrelCount: newBarrelCount,
        litresPerBarrel: newLitresPerBarrel,
    });
    
        // Update the row with new values
        updatedRows[index] = {
            ...updatedRows[index],
            grossWeight: newGrossWeight,
            tareWeight: newTareWeight,
            netWeight: updatedRows[index].isManualNetWeight ? updatedRows[index].netWeight : newNetWeight,
            density: newDensity,
            barrelCount: newBarrelCount,
            litresPerBarrel: newLitresPerBarrel,
        };
     // Log final updated row
     console.log("Updated Row (after calculation):", updatedRows[index]);
        // Set the updated form data
        setFormData((prevData) => ({
            ...prevData,
            materialRows: updatedRows,
        }));
         // Log final form data after updating
    console.log("Updated formData:", {
        ...formData,
        materialRows: updatedRows,
    });
    };
    
    
    const handleManualNetWeightChange = (index) => {
        setFormData((prevData) => ({
          ...prevData,
          materialRows: prevData.materialRows.map((row, rowIndex) => {
            if (rowIndex === index) {
              return {
                ...row,
                isManualNetWeight: !row.isManualNetWeight,
                grossWeight: !row.isManualNetWeight ? '' : row.grossWeight,
                tareWeight: !row.isManualNetWeight ? '' : row.tareWeight,
                netWeight: !row.isManualNetWeight ? '' : row.netWeight,
              };
            }
            return row;
          }),
        }));
      };
    

 const handleSelectChange = (index, selectedOption) => {
    const updatedRows = [...formData.materialRows];
    updatedRows[index].fuelName = selectedOption ? selectedOption.value : '';
    setFormData((prevData) => ({
      ...prevData,
      materialRows: updatedRows,
    }));
  };
   // Add new row
   const addRow = () => {
    setFormData((prevData) => ({
      ...prevData,
      materialRows: [
        ...prevData.materialRows,
        { tankNo: '', fuelName: '', grossWeight: '', tareWeight: '', netWeight: '', density: '' },
      ],
    }));
  };
   // Remove row
   const removeRow = (index) => {
    const updatedRows = formData.materialRows.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      materialRows: updatedRows,
    }));
  };
     
      
      const handleUOMChange = (e) => {
        const newUOM = e.target.value;
    setFormData(prevData => {
            let newNetWeight = '';
            
            if (newUOM !== prevData.uom) {
                return {
                    ...prevData,
                    uom: newUOM,
                    materialRows: prevData.materialRows.map(row => ({
                        ...row, // Spread the existing row
                        grossWeight: '',  // Reset the specific fields for each row
                        tareWeight: '',
                        density: '',
                        netWeight: '',
                        barrelCount: '',
                        litresPerBarrel: '',
                    })),
                };
            }
 return prevData;
        });
    };

    const handleSave = async (e) => {
        e.preventDefault(); 
        const requiredFields = [
            'purchaseOrderNo',
            'invoiceNo',
            'ewayBillNo',
            'date',
            'vehicleNo',
            'driverName',
            'inandout',
            'location',
            'uom',
            'color',
            'manifestNo',
            'schedule',
            'pollutionboard',
            'remarks'
        ];
        
        // Validate required fields in formData
        for (let field of requiredFields) {
            if (!formData[field]) {
                toast.error(`${field} is required.`);
                return;
            }
        }
    
        // Validate each material row
        for (let i = 0; i < formData.materialRows.length; i++) {
            const row = formData.materialRows[i];
    
              // Check if net weight is manual for this row
        const isRowManualNetWeight = row.isManualNetWeight || false;

        if (formData.uom !== 'Barrel' && !isRowManualNetWeight) {
                if (!row.grossWeight) {
                    toast.error(`Gross Weight is required for material row ${i + 1}`);
                    return;
                }
                if (!row.tareWeight) {
                    toast.error(`Tare Weight is required for material row ${i + 1}`);
                    return;
                }
            }
    
            if (formData.uom === 'Barrel') {
                if (!row.barrelCount || row.barrelCount <= 0) {
                    toast.error(`Barrel count is required for material row ${i + 1}`);
                    return;
                }
                if (!row.litresPerBarrel || row.litresPerBarrel <= 0) {
                    toast.error(`Litres per barrel is required for material row ${i + 1}`);
                    return;
                }
            }
    
            if (['MTS', 'Kgs'].includes(formData.uom)) {
                if (!row.density || row.density <= 0) {
                    toast.error(`Density is required for material row ${i + 1}`);
                    return;
                }
            }
        }
    
        // Construct payload for saving
        const savingFormData = {
            purchaseOrderNo: formData.purchaseOrderNo,
            invoiceNo: formData.invoiceNo,
            ewayBillNo: formData.ewayBillNo,
            date: formData.date,
            vehicleNo: formData.vehicleNo,
            driverName: formData.driverName,
            inandout: formData.inandout,
            location: formData.location,
            uom: formData.uom,
            color: formData.color,
            remarks: formData.remarks,
            manifestNo: formData.manifestNo,
            schedule: formData.schedule,
            pollutionboard: formData.pollutionboard,
            materialRows: formData.materialRows.map(row => ({
                tankNo: row.tankNo || 'N/A',
                fuelName: row.fuelName,
                hsnCode: row.hsnCode,
                grossWeight: isManualNetWeight ? 0 : (row.grossWeight || 0),
                tareWeight: isManualNetWeight ? 0 : (row.tareWeight || 0),
                netWeight: row.netWeight || 0,
                barrelCount: row.barrelCount || 0, 
                litresPerBarrel: row.litresPerBarrel || 0,
                density: row.density || 0,
            })),
        };
    
        // Additional validations and API call
        if (isOutWard && !savingFormData.inandout) {
            toast.error('Details cannot be empty.');
            return;
        }
    
        if (!validateVehicleNo(savingFormData.vehicleNo)) {
            toast.error('Vehicle No must be in the correct format');
            return;
        }
    
        try {
            const endpoint = isOutWard ? 'outward' : 'inward';
            console.log(`Saving ${endpoint} data with:`, savingFormData);
            const dataResponse = await axios.post(`${config.apiUrl}/saveio/${endpoint}`, savingFormData);
            
            if (dataResponse.status === 200 || dataResponse.status === 201) {
                toast.success('Data saved successfully');
                resetFormFields();
            } else {
                toast.error(`Failed to save ${endpoint} details. Please try again.`);
            }
        } catch (dataError) {
            console.error('Error saving data:', dataError);
            if (dataError.response) {
                const { status, data } = dataError.response;
                if (status === 404) {
                    toast.error(data.message);
                } else if (status === 400) {
                    toast.error(data.message || `Failed to save details. Please try again.`);
                } else if (status >= 500) {
                    toast.error(`Server error: ${data.message || 'Please try again later.'}`);
                } else {
                    toast.error(`Error saving ${isOutWard ? 'outward' : 'inward'} details!`);
                }
            } else {
                toast.error(`Error saving ${isOutWard ? 'outward' : 'inward'} details!`);
            }
        }
    };
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Define required fields for validation
        const requiredFields = [
            'purchaseOrderNo',
            'invoiceNo',
            'ewayBillNo',
            'date',
            'vehicleNo',
            'driverName',
            'location',
            'uom',
            'color',
            'manifestNo',
            'schedule',
            'pollutionboard',
            'remarks'
        ];
        
        // Validate required fields
        for (let field of requiredFields) {
            if (!formData[field]) {
                toast.error(`${field} is required.`);
                return;
            }
        }
    
        // Validate material rows
        for (let i = 0; i < formData.materialRows.length; i++) {
            const row = formData.materialRows[i];
    
             // Check if net weight is manual for this row
        const isRowManualNetWeight = row.isManualNetWeight || false;

        if (formData.uom !== 'Barrel' && !isRowManualNetWeight) {
                if (!row.grossWeight) {
                    toast.error(`Gross Weight is required for material row ${i + 1}`);
                    return;
                }
                if (!row.tareWeight) {
                    toast.error(`Tare Weight is required for material row ${i + 1}`);
                    return;
                }
            }
    
            if (formData.uom === 'Barrel') {
                if (!row.barrelCount || row.barrelCount <= 0) {
                    toast.error(`Barrel count is required for material row ${i + 1}`);
                    return;
                }
                if (!row.litresPerBarrel || row.litresPerBarrel <= 0) {
                    toast.error(`Litres per barrel is required for material row ${i + 1}`);
                    return;
                }
            }
    
            if (['MTS', 'Kgs'].includes(formData.uom)) {
                if (!row.density || row.density <= 0) {
                    toast.error(`Density is required for material row ${i + 1}`);
                    return;
                }
            }
        }
    
        // Construct payload for submission
        const updatedFormData = {
            ...formData,
            materialRows: formData.materialRows.map(row => ({
                tankNo: formData.uom === 'Barrel' ? 'N/A' : row.tankNo || 'N/A',
                fuelName: row.fuelName,
                hsnCode: row.hsnCode,
                grossWeight: isManualNetWeight ? 0 : (row.grossWeight || 0),
                tareWeight: isManualNetWeight ? 0 : (row.tareWeight || 0),
                netWeight: row.netWeight || 0,
                barrelCount: row.barrelCount || 0,
                litresPerBarrel: row.litresPerBarrel || 0,
                density: row.density || 0,
            })),
        };
    
        // Additional validations
        if (isOutWard && !updatedFormData.inandout) {
            toast.error('Details cannot be empty.');
            return;
        }
    
        if (!validateVehicleNo(updatedFormData.vehicleNo)) {
            toast.error('Vehicle No must be in the correct format');
            return;
        }
    
        try {
            const endpoint = isOutWard ? 'outward' : 'inward';
    
            // Perform the API call to submit the form data
            const dataResponse = await axios.post(`${config.apiUrl}/in_out/${endpoint}`, updatedFormData);
    
            if (dataResponse.status === 200) {
                toast.success('Data submitted successfully');
                if (formData.uom === 'Barrel' && !isOutWard) 
                   
                    try { 
                        const BarrelUpdates = updatedFormData.materialRows.map(row => ({
                            yard:'N/A',
                            barrelCount:row.barrelCount,
                            Litres_Per_Barrel:row.litresPerBarrel,
                            qty: row.netWeight,
                            Material: row.fuelName
                        }));
                   const barrelResponse = await axios.post(`${config.apiUrl}/stock/barrel-stock`,
                     { BarrelUpdates }
                    ); 
                     if (barrelResponse.status === 200) 
                        { 
                            toast.success('Barrel stock updated successfully'); 
                            resetFormFields(); 
                            setIsManualNetWeight(false); 
                        } 
                     else { 
                        toast.error(`Failed to update barrel stock: ${barrelResponse.data.message}`); 
                    } 
                    }
                 catch (barrelError) {
                     console.error('Error updating barrel stock:', barrelError); 
                     toast.error('Failed to update barrel stock. Please try again.');
                     }
                     else{
                const stockUpdates = updatedFormData.materialRows.map(row => ({
                    tankNo: row.tankNo,
                    netWeight: row.netWeight,
                    fuelName: row.fuelName
                }));
    
                try {
                    const stockResponse = await axios.put(`${config.apiUrl}/stock/in_out`, {
                        updates: stockUpdates,
                        isOutWard: isOutWard
                    });
    
                    if (stockResponse.status === 200) {
                        toast.success('Stock updated successfully');
                        resetFormFields();
                        setIsManualNetWeight(false); // Reset manual net weight flag
                    } else {
                        toast.error(`Failed to update stock: ${stockResponse.data.message}`);
                    }
                } catch (stockError) {
                    console.error('Error updating stock:', stockError);
                    toast.error('Failed to update stock. Please try again.');
                }
            }
            } else if (dataResponse.status === 400) {
                const { message } = dataResponse.data;
                toast.error(message);  // Show specific error message, including the invoice number
            } else {
                toast.error(`Failed to submit ${isOutWard ? 'outward' : 'inward'} details. Please try again.`);
            }
        } catch (dataError) {
            console.error('Error submitting data:', dataError);
        
            if (dataError.response) {
                const { status, data } = dataError.response;
        
                if (status === 400) {
                    // Display the specific error message from the backend
                    toast.error(data.message || 'Bad Request. Please check your input.');
                } else if (status === 404) {
                    toast.error(data.message || 'Resource not found.');
                } else if (status >= 500) {
                    toast.error('Server error. Please try again later.');
                } else {
                    toast.error(`Unexpected error: ${data.message || 'An error occurred.'}`);
                }
            } else if (dataError.request) {
                // No response received from the server
                console.error('No response received:', dataError.request);
                toast.error('No response from the server. Please check your network connection.');
            } else {
                // Error occurred in setting up the request
                console.error('Request setup error:', dataError.message);
                toast.error(`Request error: ${dataError.message}`);
            }
        }

    };
    

    const resetFormFields = () => {
        setFormData({
            purchaseOrderNo: '',
            invoiceNo: '',
            ewayBillNo: '',
            date: '',
            vehicleNo: '',
            driverName: '',
            inandout: '',
            location: '',
            uom: '',
            color: '',
            manifestNo:'',
            schedule:'',
            pollutionboard:'',
            remarks: '',
            materialRows: [
                {
                    tankNo: '',
                    fuelName: '',
                    hsnCode: '',
                    grossWeight: '',
                    tareWeight: '',
                    netWeight: '',
                    density: '',
                },
            ],
        });
        setIsOutWard(false); 
    };

    useEffect(() => {
        fetchTankOptions();
    }, []);
  
    const openTable = () => {
        setIstableOpen(true);
      };
      
      const closeTable = () => {
        setIstableOpen(false);
      };
      const openModal = () => {
        setShowModal(true)
      }
      const closeModal = () => {
        setShowModal(false)
      }
      const handlesaveChange = () => {
        setIsSaving((prev) => !prev);
      };

      return (
        <>
       <div className="bg-gray-900 min-h-screen p-6">
       <div className="bg-gray-800  text-white rounded-lg shadow-lg p-4">
        <button onClick={openModal} className=" px-2 py-1.5 bg-white rounded text-yellow-600 float-left flex items-center">
        <HiOutlineSave className='h-4 w-5' />

        </button>
        <button onClick={openTable} className="px-2 py-1.5 bg-white rounded text-yellow-600 float-right flex items-center">
          <HiInformationCircle className='h-4 w-5' />

        </button>
            <h2 className="text-xl font-bold mb-4 text-center">{isOutWard ? 'Outward Form' : 'Inward Form'}</h2>
            <form className="space-y-4" onSubmit={handleSubmit}>
                <div className="grid grid-cols-3 gap-4">
                    <div>
                        <label className="block text-sm font-medium">Invoice Number<span className="text-red-500">*</span></label>
                        <input 
                            type="text" 
                            name="invoiceNo" 
                            value={formData.invoiceNo}
                            onChange={handleChange}
                            className="mt-1 block w-full border text-black border-gray-300 rounded-md shadow-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">Purchase Order No<span className="text-red-500">*</span></label>
                        <input 
                            type="text" 
                            name="purchaseOrderNo" 
                            value={formData.purchaseOrderNo}
                            onChange={handleChange}
                            className="mt-1 block w-full  text-black border border-gray-300 rounded-md shadow-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">e-way Bill No<span className="text-red-500">*</span></label>
                        <input 
                            type="number" 
                            name="ewayBillNo" 
                            value={formData.ewayBillNo}
                            onChange={handleChange}
                            onWheel={(e) => e.target.blur()} // Disable scroll on focus
                            className="mt-1 block w-full border text-black border-gray-300 rounded-md shadow-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">{isOutWard ? 'Date of Delivery' : 'Date of Arrival'} <span className="text-red-500">*</span></label>
                        <input 
                            type="date" 
                            name="date" 
                            value={formData.date}
                            onChange={handleChange}
                            className="mt-1 block w-full border text-black border-gray-300 rounded-md shadow-sm" 
                            required 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">Vehicle No <span className="text-red-500">*</span></label>
                        <input 
                            type="text" 
                            name="vehicleNo"
                            value={formData.vehicleNo}
                            onChange={handleChange}
                            
                            title="e.g. TN 12 BD 1234 or TN 12 C 1234"
                            className="mt-1 block w-full border text-black border-gray-300 rounded-md shadow-sm" 
                            required 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">Driver Name <span className="text-red-500">*</span></label>
                        <input 
                            type="text" 
                            name="driverName" 
                            value={formData.driverName}
                            onChange={handleChange}
                            className="mt-1 block w-full border text-black border-gray-300 rounded-md shadow-sm" 
                            required 
                            title="Driver Name must contain only uppercase letters and spaces."
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">{isOutWard ? 'OutWard to Company' : 'Inward from Company'}<span className="text-red-500">*</span></label>
                        <input 
                            type="text" 
                            name="inandout"
                            value={formData.inandout}
                            onChange={handleChange}
                            className="mt-1 block w-full border text-black border-gray-300 rounded-md shadow-sm" 
                            pattern="[a-zA-Z\s]+" 
                            required 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">From Location <span className="text-red-500">*</span></label>
                        <input 
                            type="text" 
                            name="location" 
                            value={formData.location}
                            onChange={handleChange}
                            className="mt-1 block w-full border text-black border-gray-300 rounded-md shadow-sm" 
                            pattern="[a-zA-Z\s]+" 
                            required 
                        />
                    </div>
                    
                    <div>
    <label className="block text-sm font-medium">
        UOM <span className="text-red-500">*</span>
    </label>
    <select
        name="uom"
        value={formData.uom}
        onChange={handleUOMChange}
        className="mt-1 block w-full border text-gray-900 border-gray-300 rounded-md shadow-sm"
        required
    >
       <option value="" disabled>Select UOM</option>
        <option value="Kgs">Kgs</option>
        <option value="KL">KL</option>
        <option value="Barrel">Barrel</option>
        <option value="MTS">MTS</option>
        <option value="Litre">Litre</option>
        <option value="cm">cm</option> 
       
    </select>
</div>


<div>
            <label className="block text-sm font-medium">
                Color <span className="text-red-500">*</span>
                <FaPlus
                    className="inline-block ml-2 mb-1 cursor-pointer text-blue-600"
                    onClick={handleOpenModal}
                />
            </label>
            <select
                name="color"
                value={formData.color}
                onChange={handleChange}
                className="mt-1 block w-full border text-gray-900 border-gray-300 rounded-md shadow-sm"
                required
            >
                <option value="" disabled>Select Color</option>
                {customColors.map((color, index) => (
                    <option key={index} value={color}>{color}</option>
                ))}
            </select>
                    </div>
                   
                 
        <div>
                        <label className="block text-sm font-medium">Manifest No</label>
                        <input
                        
                            name="manifestNo"
                            value={formData.manifestNo}
                            onChange={handleChange}// Use unified function
                            className="mt-1 block w-full  text-gray-900 border border-gray-300 rounded-md shadow-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">Schedule</label>
                        <input
                            name="schedule"
                            value={formData.schedule}
                            onChange={handleChange}// Use unified function
                            className="mt-1 block w-full  text-gray-900 border border-gray-300 rounded-md shadow-sm"
                        />
                    </div>
                    <div>
    <label className="block text-sm font-medium">Pollution Board</label>
    <select
        name="pollutionboard"
        value={formData.pollutionboard}
        onChange={handleChange}// Use unified function
        className="mt-1 block w-full text-gray-900 border border-gray-300 rounded-md shadow-sm"
    >
        <option value="">Select an option</option>
        <option value="yes">Yes</option>
        <option value="no">No</option>
    </select>
</div>

                    <div>
                        <label className="block text-sm font-medium">Remarks</label>
                        <input
                            name="remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                            className="mt-1 block w-full  text-gray-900 border border-gray-300 rounded-md shadow-sm"
                        />
                    </div>
                    
                </div>
                {/* Dynamic Material Rows Table */}
                <div className='max-h-[calc(100vh-505px)] overflow-y-auto'>
                <table className="min-w-full bg-gray-800  shadow-sm mb-4">
  <thead className='text-center bg-teal-500 text-white text-xs sticky top-0 z-10'>
    <tr>
    {!isSaving && formData.uom !== 'Barrel'  && (
      <th className="px-2 py-2 text-sm font-medium">Tank No</th>
    )}
      <th className="px-2 py-2 text-sm font-medium text-nowrap">Fuel Name</th>
      <th className="px-2 py-2 text-sm font-medium">HSN Code</th>

      {formData.uom !== 'Barrel' && (
                        <>
      <th className="px-2 py-2 text-sm font-medium">Gross Weight</th>
      <th className="px-2 py-2 text-sm font-medium">Tare Weight</th>
      </>
      )}
    <th className="px-2 py-2 text-sm font-medium">Net Weight</th>
      {formData.uom === 'Barrel' && (
                        <>
    <th className="px-2 py-2 text-sm font-medium">Number of Barrels</th> 
    <th className="px-2 py-2 text-sm font-medium">Litres per Barrel</th> 
    </> )}
    
      {formData.uom !== 'cm' && formData.uom !== 'Barrel' && formData.uom !== 'Litre' &&( 
      <th className="px-2 py-2 text-sm font-medium">Density</th>
    )}
      <th className="px-2 py-2 text-sm font-medium">Actions</th>
    </tr>
  </thead>
  <tbody className='text-black'>
    {formData.materialRows.map((row, index) => (
      <tr key={index}>
           {!isSaving && formData.uom !== 'Barrel'  &&(
        <td className="px-2 py-2">
          <div className="flex items-center space-x-2">
            <Select
              className="text-gray-900"
              options={tankOptions}
              value={tankOptions.find(option => option.value === row.tankNo) || null} // Set current selected value
              onChange={(selectedOption) => {
                const updatedRows = [...formData.materialRows];
                updatedRows[index].tankNo = selectedOption ? selectedOption.value : '';
                setFormData((prevData) => ({
                  ...prevData,
                  materialRows: updatedRows,
                }));
              }}
              styles={{
                control: (provided,state) => ({
                  ...provided,
                  width: '100px',
                  height: '30px', // Set a consistent height
                  minHeight: '30px',
                  boxShadow: 'none',
                  borderRadius: '0.375rem',
                  fontSize: '0.75rem',
                  padding: '0', // Remove padding to align text correctly
                  display: 'flex',
                  alignItems: 'center', // Center text vertically
                  justifyContent: 'center', // Center the text horizontally
                }),
                menu: (provided) => ({
                  ...provided,
                  width: '120%', // Increase dropdown menu width to make it wider
                  minWidth: '150px',
                  zIndex: 9999,
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: '0.75rem',
                  lineHeight: '23px', // Match line height to control height
                  margin: 0, // Remove any margin for better alignment
                }),
                singleValue: (provided) => ({
                  ...provided,
                  fontSize: '0.75rem',
                  lineHeight: '23px', // Match line height to control height
                }),
                option: (provided) => ({
                  ...provided,
                  fontSize: '0.75rem',
                  padding: '0.25rem 0.5rem',
                  lineHeight: '1rem',
                }),
              }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              placeholder="Select"
              required
            />
          </div>
        </td>
           )}
        <td className="px-2 py-2">
          <Select
            className='text-gray-900'
            value={materialOptions.find(option => option.value === row.fuelName) || null}
            onChange={(selectedOption) => handleSelectChange(index, selectedOption)}
            options={materialOptions}
            styles={{
              control: (provided) => ({
                ...provided,
                width: '200px', // Set static width for Select
                height: '30px', // Set a consistent height
                minHeight: '30px',
                boxShadow: 'none',
                borderRadius: '0.375rem',
                fontSize: '0.75rem',
                padding: '0', // Remove padding to align text correctly
                display: 'flex',
                alignItems: 'center', // Center text vertically
                
              }),
              menu: (provided) => ({
                ...provided,
                width: '150%', // Increase dropdown menu width to make it wider
                minWidth: '150px',
                zIndex: 9999,
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              placeholder: (provided) => ({
                ...provided,
                fontSize: '0.75rem',
                lineHeight: '23px', // Match line height to control height
                margin: 0, // Remove any margin for better alignment
              }),
              singleValue: (provided) => ({
                ...provided,
                fontSize: '0.75rem',
                lineHeight: '23px', // Match line height to control height
              }),
              option: (provided) => ({
                ...provided,
                fontSize: '0.75rem',
                padding: '0.25rem 0.5rem',
                lineHeight: '1rem',
              }),
            }}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            placeholder="Select"
            required
          />
        </td>
        <td className="px-2 py-2">
          <input
            type="number"
            name="hsnCode"
            value={row.hsnCode}
            onChange={(e) => handleRowChange(index, e)}
            onWheel={(e) => e.target.blur()} // Disable scroll on focus
            className="w-full border border-gray-300 rounded-md text-sm h-7"
            step="0.01"
            required
          />
        </td>
           {formData.uom !== 'Barrel' && (
                        <>
        <td className="px-2 py-2">
          <input
            type="text"
            name="grossWeight"
            value={row.grossWeight}
            onChange={(e) => handleRowChange(index, e)}
            onWheel={(e) => e.target.blur()} // Disable scroll on focus
            className="w-full border border-gray-300 rounded-md text-sm h-7"
            step="0.01"
            required
            disabled={row.isManualNetWeight} 
          />
        </td>
       
         
        <td className="px-2 py-2">
          <input
            type="text"
            name="tareWeight"
            value={row.tareWeight}
            onChange={(e) => handleRowChange(index, e)}
            onWheel={(e) => e.target.blur()} // Disable scroll on focus
            className="w-full border border-gray-300 rounded-md text-sm h-7"
            step="0.01"
            required
            disabled={row.isManualNetWeight} 
          />
        </td>
        </>
         )}
        <td className="px-2 py-2">
        <div className="flex items-center space-x-2">
        <input
            className=""
            type="checkbox"
            checked={row.isManualNetWeight}
            onChange={() => handleManualNetWeightChange(index)}
        />
          <input
            type="text"
            name="netWeight"
            value={row.netWeight}
            onChange={(e) => handleRowChange(index, e)}
            onWheel={(e) => e.target.blur()} // Disable scroll on focus
            className="w-full border border-gray-300 rounded-md text-sm h-7"
            step="0.01"
            required
            disabled={!row.isManualNetWeight}
          />
          </div>
        </td>
        {formData.uom !== 'cm' && formData.uom !== 'Barrel' && formData.uom !== 'Litre' &&( 
        <td className="px-2 py-2">
          <input
            type="text"
            name="density"
            value={row.density}
            onChange={(e) => handleRowChange(index, e)}
            onWheel={(e) => e.target.blur()} // Disable scroll on focus
            className="w-full border border-gray-300 rounded-md text-sm h-7"
            step="0.01"
            required
          />
        </td>
        )}
        {formData.uom === 'Barrel' && (
            <>
         <td className="px-2 py-2">
         <input 
                                    type="number" 
                                    name="barrelCount" 
                                    value={row.barrelCount}
                                    onChange={(e) => handleRowChange(index, e)}
                                    onWheel={(e) => e.target.blur()} // Disable scroll on focus
                                    className="w-full border border-gray-300 rounded-md text-sm h-7"
                                    min="1"
                                    required 
                                />
                                </td>
                                <td className="px-2 py-2">
                                <input 
                                    type="number" 
                                    name="litresPerBarrel" 
                                    value={row.litresPerBarrel}
                                    onChange={(e) => handleRowChange(index, e)}
                                    onWheel={(e) => e.target.blur()} // Disable scroll on focus
                                    className="w-full border border-gray-300 rounded-md text-sm h-7"
                                    min="200"
                                    max="230"
                                    required 
                                />
                                </td>
                                </>
        )}
        <td className="px-2 py-2 text-center">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => removeRow(index)}
            className="text-red-600 hover:text-red-800 cursor-pointer"
            title="Remove Row"
          />
        </td>
      </tr>
    ))}
  </tbody>
</table>

                </div>
                <button
        onClick={addRow}
        className="w-32 px-3 py-1.5 text-xs bg-[#3081b8] text-white rounded-md hover:bg-[#173a52]"
      >
        Add Row
      </button>


     
      <div className="flex items-center justify-between mt-4">
  {/* Checkbox on the left */}
  <div className="flex items-center space-x-2">
    <input
      type="checkbox"
      checked={isOutWard}
      onChange={handleCheckboxChange}
      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
    />
    <label className="text-sm font-medium">Check for Outward Form</label>
    <input
            type="checkbox"
            checked={!isSaving}  // Checkbox reflects if saving is enabled or not
            onChange={handlesaveChange}  // Toggle isSaving state
        />
        <label className="text-sm font-medium">
        {isSaving ? 'Submit' : 'Save'}
      </label>
  </div>

  {/* Buttons centered */}
  <div className="flex space-x-4">
    {isSaving ? (
      <button
        type="button"
        onClick={handleSave}
        className="w-32 px-3 py-1.5 text-xs text-white bg-blue-600 hover:bg-blue-700 rounded-md shadow-md"
      >
        Save
      </button>
    ) : (
      <button
        type="submit"
        className="w-32 px-3 py-1.5 text-xs text-white bg-blue-600 hover:bg-blue-700 rounded-md shadow-md"
      >
        Submit
      </button>
    )}
    <button
      type="button"
      onClick={resetFormFields}
      className="w-32 px-3 py-1.5 text-xs text-white bg-red-600 hover:bg-red-700 rounded-md shadow-md"
    >
      Cancel
    </button>
  </div>
</div>



            </form>
            <ColorModal 
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onAddColor={handleAddColor}
            />

{istableOpen && <InoutHistory closeTable={closeTable} />}  
{showModal && <SaveModal closeModal={closeModal} setFormData={setFormData} setIsSaving={setIsSaving} handleSaveData={handleSaveData} selectedDataType={selectedDataType} 
          setSelectedDataType={setSelectedDataType} setIsOutWard={setIsOutWard} />}
        </div>
        </div>
        </>
    );
};

export default InWardForm;
