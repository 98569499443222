import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';

const MiscellaneousExpenses = ({ MEdown }) => {
  const [formData, setFormData] = useState({
    item: '',
    quantity: '',
    uom: '', // No default value
    customUom: '',
    amount: '',
    purchaseDate: new Date(),
    purchaseDepartment: '', // No default value
    customDepartment: '', // To store custom department if "Other" is selected
  });

  const [isCustomUom, setIsCustomUom] = useState(false); // State to track if custom UOM is selected
  const [isCustomDepartment, setIsCustomDepartment] = useState(false); // State to track if custom department is selected

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, purchaseDate: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation to ensure required fields are filled
    if ((!formData.uom && !formData.customUom) || (!formData.purchaseDepartment && !formData.customDepartment)) {
      toast.error('Please select both UOM and Purchase Department!');
      return;
    }

    try {
      await axios.post(`${config.apiUrl}/meexpenses/expenses`, {
        ...formData,
        uom: isCustomUom ? formData.customUom : formData.uom,
        is_custom_uom: isCustomUom,
        purchaseDate: format(formData.purchaseDate, 'yyyy-MM-dd'), 
        purchaseDepartment: isCustomDepartment ? formData.customDepartment : formData.purchaseDepartment,
        is_custom_department: isCustomDepartment,
      });
      toast.success('Expense added successfully!');
      setFormData({
        item: '',
        quantity: '',
        uom: '',
        amount: '',
        purchaseDate: new Date(),
        purchaseDepartment: '',
        customUom: '',
        customDepartment: '',
      });
    } catch (error) {
      toast.error('Error adding expense!');
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="p-6 rounded-md w-full max-w-lg bg-white shadow-lg transform transition-all relative text-black">
        <h1 className="text-2xl font-bold text-gray-800 mb-6 border-b border-gray-300 pb-2">Miscellaneous Expenses</h1>
        <button onClick={() => MEdown()} className="absolute top-2 right-2">
          <FontAwesomeIcon className="text-red-500" icon={faTimes} />
        </button>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Item</label>
              <input
                type="text"
                name="item"
                value={formData.item}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Purchase Date</label>
              <DatePicker
                selected={formData.purchaseDate}
                onChange={handleDateChange}
                isClearable
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                wrapperClassName="w-full"
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Unit of Measurement</label>
              <select
                name="uom"
                value={formData.uom}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                required
              >
                <option value="" disabled>Select UOM</option>
                <option value="count">Count</option>
                <option value="kgs">Kgs</option>
                <option value="other">Other</option>
              </select>
              {formData.uom === 'other' && (
                <input
                  type="text"
                  name="customUom"
                  value={formData.customUom}
                  onChange={handleInputChange}
                  onFocus={() => setIsCustomUom(true)}
                  placeholder="Enter custom UOM"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                />
              )}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Quantity</label>
              <input
                type="number"
                name="quantity"
                value={formData.quantity}
                onChange={handleInputChange}
                onWheel={(e) => e.target.blur()}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Amount</label>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                onWheel={(e) => e.target.blur()}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Purchase Department</label>
              <select
                name="purchaseDepartment"
                value={formData.purchaseDepartment}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                required
              >
                <option value="" disabled>Select Department</option>
                <option value="Kitchen">Kitchen</option>
                <option value="PD">PD</option>
                <option value="other">Other</option>
              </select>
              {formData.purchaseDepartment === 'other' && (
                <input
                  type="text"
                  name="customDepartment"
                  value={formData.customDepartment}
                  onChange={handleInputChange}
                  onFocus={() => setIsCustomDepartment(true)}
                  placeholder="Enter custom department"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                />
              )}
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="text-sm px-3 py-1.5 bg-rose-500 hover:bg-rose-600 text-white rounded-md transition"
            >
              Add Expense
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MiscellaneousExpenses;
