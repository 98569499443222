import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const TransferMaterialModal = ({ isOpen, stockData, onClose, fetchStockData, tankOptions }) => {
  const [sourceTank, setSourceTank] = useState('');
  const [destinationTank, setDestinationTank] = useState('');
  const [quantity, setQuantity] = useState('');
  const [sourceMaterialName, setSourceMaterialName] = useState('');
  const [sourceMaterialQuantity, setSourceMaterialQuantity] = useState('');

  // Debugging log to check if props are coming in correctly
  console.log('Stock Data:', stockData);
  console.log('Selected Source Tank:', sourceTank);
  console.log('Selected Destination Tank:', destinationTank);

  useEffect(() => {
    // Update material name and quantity when sourceTank changes
    const sourceTankData = stockData.find(tank => tank.tankname === sourceTank);
    
    if (sourceTankData) {
      setSourceMaterialName(sourceTankData.oilname);
      setSourceMaterialQuantity((parseFloat(sourceTankData.currentquantity) || 0).toFixed(2));

    } else {
      setSourceMaterialName('');
      setSourceMaterialQuantity(0);
    }
  }, [sourceTank, stockData]);

  const handleTransfer = async () => {
    const sourceTankData = stockData.find(tank => tank.tankname === sourceTank);
    const destinationTankData = stockData.find(tank => tank.tankname === destinationTank);
  
    if (!sourceTankData || !destinationTankData) {
      toast.error('Please select a valid source tank and destination tank.');
      return;
    }
  
    // Convert quantity and sourceMaterialQuantity to numbers for comparison
    const parsedQuantity = parseFloat(quantity);
    const availableQuantity = parseFloat(sourceMaterialQuantity);
  
    if (!parsedQuantity || parsedQuantity <= 0 || parsedQuantity > availableQuantity) {
      toast.error(`You entered ${parsedQuantity} KL, but the available quantity in the source tank is ${availableQuantity} KL.`);
      return;
    }
  
    const totalQuantityInDestination = parseFloat(destinationTankData.currentquantity) || 0;
    const newQuantityInDestination = totalQuantityInDestination + parsedQuantity;
  
    if (newQuantityInDestination > destinationTankData.capacity) {
      const currentQuantity = destinationTankData.currentquantity || 0;
      const excessAmount = newQuantityInDestination - destinationTankData.capacity;
  
      toast.error(`The destination tank (${destinationTankData.tankname})  ${currentQuantity} KL +  ${parsedQuantity} KL exceeds its tank capacity ${destinationTankData.capacity} KL.`);
      return;
    }
  
    try {
      const response = await axios.post(`${config.apiUrl}/stock/transfer-material`, {
        sourceTank: sourceTankData.tankname,
        destinationTank: destinationTankData.tankname,
        quantity: parsedQuantity,  // Pass parsed quantity
        materialName: sourceMaterialName,
      });
  
      toast.success(response.data.message);  // Success message from backend
      onClose();
      fetchStockData();
    } catch (error) {
      console.error('Error during transfer:', error);
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);  // Error message from backend
    }
  };
  
  

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded shadow-lg w-full max-w-lg relative text-black">
          <h2 className="mb-4">Transfer Material</h2>
          <button onClick={onClose} className="absolute top-2 right-2">
                    <FontAwesomeIcon className="text-red-500" icon={faTimes} />
                </button>

          <div>
            <label className="block text-gray-700 mb-1">Source Tank:</label>
            <select
              value={sourceTank}
              onChange={(e) => {
                setSourceTank(e.target.value);
                setQuantity(''); // Reset quantity when changing source tank
              }}
              className="border form-input w-full rounded-md text-sm p-2"
            >
              <option value="">Select Tank</option>
              {tankOptions.map((tank) => (
                <option key={tank.tankname} value={tank.tankname}>
                  {tank.tankname}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-4">
            <label className="block text-gray-700 mb-1">Material Name:</label>
            <input
              type="text"
              value={sourceMaterialName}
              readOnly
              className="border form-input w-full rounded-md text-sm p-2"
            />
          </div>

          <div className="mt-4">
  <label className="block text-gray-700 mb-1">Quantity in Source Tank:</label>
  <input
    type="text"
    value={Number(sourceMaterialQuantity).toFixed(2)} // Ensures two decimal places
    readOnly
    className="border form-input w-full rounded-md text-sm p-2"
  />
</div>


          <div className="mt-4">
            <label className="block text-gray-700 mb-1">Destination Tank:</label>
            <select
              value={destinationTank}
              onChange={(e) => setDestinationTank(e.target.value)}
              className="border form-input w-full rounded-md text-sm p-2"
            >
              <option value="">Select Tank</option>
              {tankOptions.map((tank) => (
                <option key={tank.tankname} value={tank.tankname}>
                  {tank.tankname}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-4">
            <label className="block text-gray-700 mb-1">Quantity to Transfer (KL):</label>
            <input
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              className="border form-input w-full rounded-md text-sm p-2"
              min="1"
              max={sourceMaterialQuantity || 0}
            />
          </div>

          <div className="flex justify-end mt-4">
            <button
              onClick={handleTransfer}
              className="px-3 py-1.5 text-sm bg-rose-500 hover:bg-rose-600 transition text-white rounded-md"
            >
              Transfer
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default TransferMaterialModal;
