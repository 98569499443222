import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import SkeletonLoader from '../SkeletonLoader';
import StockList from './StockList';
import StockEditModal from './StockEditModal';
import StockAddModal from './StockAddModal';
import AddTankModal from './AddTank';
import TransferMaterialModal from './TransferMaterialModal';
import MaterialModal from './MaterialModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import { faPlus, faExchangeAlt,faClipboardList} from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FaDownload } from 'react-icons/fa';
import MaterialEditModal from './MaterialEditModal';
import Download from './Download';
import EditTankModal from './EditTankModal';
import BarrelModal from './BarrelStockModal';
import BarrelStockTable from './Barreltable';
import MiscellaneousExpenses from './MeModal';
import TwostockModal from './TwoModal';

const StockTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingStock, setEditingStock] = useState(false);
  const [addingStock, setAddingStock] = useState(false);
  const [tankModalOpen, setTankModalOpen] = useState(false);
  const [editTankModalOpen, setEditTankModalOpen] = useState(false);
  const [materialOpen, setMaterialOpen] = useState(false);
  const [materialToEdit, setMaterialToEdit] = useState(false);
  const [isDownloadOpen,setIsDownloadopen] = useState(false);
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const [miscellaneousExpensesOpen, setMiscellaneousExpensesOpen] = useState(false);
  const [twoModalOpen, setTwoModalOpen] = useState(false);


  const [formData, setFormData] = useState({
    tankname: '',
    oilname: '',
    currentquantity: '',
    materialtype: '',
    radius: '',
    capacity: '',
  });
  const [tankOptions, setTankOptions] = useState([]);

  const fetchStockData = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/stock/getstockData`);
      if (response.data && Array.isArray(response.data.data)) {
        setStockData(response.data.data);
        console.log('stock table',response.data.data);
        
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching stock data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTankOptions = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/stock/getTanks`);
      if (response.data && Array.isArray(response.data.data)) {
        setTankOptions(response.data.data);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching tank options:', error);
    }
  };
 

  useEffect(() => {
    fetchStockData();
    fetchTankOptions();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredStockData = stockData.filter((item) =>
    Object.values(item).some((value) =>
      value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleEditClick = (item) => {
    setFormData({
      tankname: item.tankname,
      oilname: item.oilname,
      currentquantity: 0,
      materialtype: item.materialtype,
      radius: item.tankradius || '',
      capacity: item.capacity || '',
    });
    setEditingStock(true);
  };

  const handleAddClick = () => {
    setFormData({
      tankname: '',
      oilname: '',
      currentquantity: 0,
      materialtype: '',
      radius: '',
      capacity: '',
    });
    setAddingStock(true);
  };

  const handleOpenTankModal = () => {
    setTankModalOpen(true);
  };

  const handleCloseTankModal = () => {
    setTankModalOpen(false);
  };
const handleOpenMaterialModal = () => {
  setMaterialOpen(true);
}
const handleCloseMaterialModal = () => {
  setMaterialOpen(false);
}
const handleEditMaterial = () => {
  setMaterialToEdit(true); 
};

const handleCloseEditMaterial = () => {
  setMaterialToEdit(false);
}
const edittankopen = () => {
  setEditTankModalOpen(true);
}
const  edittankclose = () => {
  setEditTankModalOpen(false);
}
const opendown = () => setIsDownloadopen(true);
const closedown = () => setIsDownloadopen(false);
const MEOpen = () => setMiscellaneousExpensesOpen(true);
const MEdown = () => setMiscellaneousExpensesOpen(false);

const twoopen = () => setTwoModalOpen(true);
const twodown = () => {
  setTwoModalOpen(false);
  fetchStockData();
}

const handleOpenTransferModal = () => {
  setTransferModalOpen(true);
};

const handleCloseTransferModal = () => {
  setTransferModalOpen(false);
};


return (
  <div className="w-full p-6 bg-gray-900 min-h-screen text-white">
    {/* Header Section */}
    <div className="flex items-center justify-between mb-4 p-2">
      <h3 className="text-xl font-bold uppercase">Stock</h3>
      <div className="flex space-x-2">
        <input
          type="text"
          placeholder="Search..."
          className="px-2 py-1 border border-gray-300 text-gray-900 rounded-lg focus:ring focus:ring-blue-300 focus:border-blue-500"
          value={searchTerm}
          onChange={handleSearch}
        />
         <button
          onClick={twoopen}
          className="px-2 py-1 bg-white text-yellow-600 rounded flex items-center"
        >
          <FontAwesomeIcon icon={faClipboardList} className='h-4 w-5 ' />
        </button>
        
       
        <button
          onClick={handleAddClick}
          className="px-2 py-1.5 text-xs bg-[#DFF0D8] text-[#102310] rounded-lg hover:bg-yellow-600 transition"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add New Stock
        </button>
        <button
          onClick={handleOpenTransferModal}
          className="px-2 py-1.5 text-xs bg-[#DFF0D8] text-[#102310] rounded-lg hover:bg-yellow-600 transition"
        >
            <FontAwesomeIcon icon={faExchangeAlt} className="mr-2" />
          Transfer Material
        </button>
        <button
          onClick={handleOpenTankModal}
          className="px-3 py-1.5 text-xs bg-[#DFF0D8] text-[#102310] rounded-lg hover:bg-yellow-600 transition"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add Tank
        </button>
        <button
          onClick={edittankopen}
          className="px-3 py-1.5 text-xs bg-[#DFF0D8] text-[#102310] rounded-lg hover:bg-yellow-600 transition"
        >
          <FontAwesomeIcon icon={faEdit} className="mr-2" />
          Edit Tank
        </button>
        <button
          onClick={handleOpenMaterialModal}
          className="px-3 py-1.5 text-xs bg-[#DFF0D8] text-[#102310] rounded-lg hover:bg-yellow-600 transition"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add Material
        </button>
        <button
          onClick={handleEditMaterial}
          className="px-3 py-1.5 text-xs bg-[#DFF0D8] text-[#102310] rounded-lg hover:bg-yellow-600 transition"
        >
          <FontAwesomeIcon icon={faEdit} className="mr-2" />
          Edit Material
        </button>
        <button
          onClick={opendown}
          className="px-3 py-1.5 text-xs bg-[#DFF0D8] text-[#102310] rounded-lg hover:bg-yellow-600 transition flex items-center"
        >
          <FaDownload size={14} />
          <span className="ml-2">Download</span>
        </button>
        <button
  onClick={MEOpen}
  className="px-3 py-1.5 text-xs bg-[#DFF0D8] text-[#102310] rounded-lg hover:bg-yellow-600 transition flex items-center"
>
  <FaMoneyCheckAlt size={14} /> 
</button>
      </div>
    </div>

    {/* Scrollable Table Section */}
    <div className="overflow-y-auto max-h-[90vh]">
      {loading ? (
        <SkeletonLoader height={40} count={10} />
      ) : (
        <StockList
          filteredStockData={filteredStockData}
          handleEditClick={handleEditClick}
          fetchStockData={fetchStockData}
        />
      )}
    </div>

    {/* Modals Section */}
    {editingStock && (
      <StockEditModal
        formData={formData}
        setFormData={setFormData}
        setEditingStock={setEditingStock}
        stockData={stockData}
        fetchStockData={fetchStockData}
      />
    )}
    {addingStock && (
      <StockAddModal
        formData={formData}
        setFormData={setFormData}
        tankOptions={tankOptions}
        setAddingStock={setAddingStock}
        stockData={stockData}
        fetchStockData={fetchStockData}
      />
    )}
    {tankModalOpen && (
      <AddTankModal
        isOpen={tankModalOpen}
        onClose={handleCloseTankModal}
        fetchTanks={fetchTankOptions}
      />
    )}
    {materialOpen && (
      <MaterialModal
        isOpen={materialOpen}
        onClose={handleCloseMaterialModal}
      />
    )}
    {materialToEdit && (
      <MaterialEditModal
        isEOpen={materialToEdit}
        onEClose={handleCloseEditMaterial}
        fetchStockData={fetchStockData}
      />
    )}
    {isDownloadOpen && (
      <Download closedown={closedown} stockData={stockData} />
    )}
    {editTankModalOpen && (
      <EditTankModal
      isOpen={editTankModalOpen}
      onClose={edittankclose}/>
    )}
    {transferModalOpen && (
      <TransferMaterialModal
        isOpen={transferModalOpen}
        onClose={handleCloseTransferModal}
        fetchStockData={fetchStockData}
        stockData={stockData}
        tankOptions={tankOptions}
      />
    )}
    {miscellaneousExpensesOpen && (<MiscellaneousExpenses MEdown={MEdown}/>
  )}
    {twoModalOpen  &&( <TwostockModal Onclose={twodown}/>
  )}
  </div>
);
};
export default StockTable;
