import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const MiscellaneousExpensesTable = ({ Onclose }) => {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchExpenses();
  }, []);

  const fetchExpenses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${config.apiUrl}/meexpenses/getexpenses`);
      setExpenses(response.data);
      console.log(response.data);
    } catch (err) {
      setError('Failed to fetch expenses');
      console.error('Error fetching expenses:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center text-gray-600">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-600">{error}</div>;
  }

  return (
    <div className="">
             <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
             <div className="bg-gray-800 p-6 rounded-2xl shadow-md max-w-full w-full h-full flex flex-col relative">

          <button onClick={Onclose} className="absolute top-2 right-2">
            <FontAwesomeIcon className="text-red-500" icon={faTimes} />
          </button>
          <h3 className="text-lg font-semibold mb-2 text-center text-white">
            Miscellaneous Stock
          </h3>

          <div className="overflow-x-auto">
            <table className="table-auto w-full bg-gray-700 border border-gray-500 max-h-[calc(100vh-188px)] overflow-y-auto">
              <thead className="bg-teal-600 text-white sticky top-0 text-xs text-center">
                <tr>
                  <th className="py-2 px-4">Item</th>
                  <th className="py-2 px-4">Quantity</th>
                  <th className="py-2 px-4">UOM</th>
                  <th className="py-2 px-4">Amount</th>
                  <th className="py-2 px-4">Purchase Date</th>
                  <th className="py-2 px-4">Department</th>
                </tr>
              </thead>
              <tbody>
                {expenses.length > 0 ? (
                  expenses.map((expense) => (
                    <tr
                      key={expense.id}
                      className="text-xs text-center border-b font-semibold"
                    >
                      <td className="py-2 px-4">{expense.item}</td>
                      <td className="py-2 px-4">{expense.quantity}</td>
                      <td className="py-2 px-4">{expense.uom}</td>
                      <td className="py-2 px-4">{expense.amount}</td>
                      <td className="py-2 px-4">
                        {new Date(expense.purchase_date).toLocaleDateString()}
                      </td>
                      <td className="py-2 px-4">{expense.purchase_department}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="py-4 text-center text-white text-xs"
                    >
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiscellaneousExpensesTable;
