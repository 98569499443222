import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import Select from 'react-select';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LogsheetDownloadModal = ({ closeModal }) => {
  const [batchNo, setBatchNo] = useState('');
  const [format, setFormat] = useState('');
  const [logsheetData, setLogsheetData] = useState([]);
  const [batchOptions, setBatchOptions] = useState([]);

  // Fetch all logsheet data and extract batch numbers
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/logsheet/all-data`);
        const data = response.data;
   
        

        // Extract unique batch numbers
        const uniqueBatchNos = [...new Set(data.map(item => item.batchNo))];
        const options = uniqueBatchNos.map(batch => ({ value: batch, label: batch }));
        setBatchOptions(options);

        // Set logsheet data for the first batch number or none initially
        setLogsheetData(data);
      } catch (error) {
        console.error('Error fetching logsheet data:', error);
      }
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    if (batchNo) {
      // Filter data based on selected batch number
      setLogsheetData(logsheetData.filter(item => item.batchNo === batchNo));
    }
  }, [batchNo]);

  const handleDownload = () => {
    
    if (!batchNo) {
      toast.error('Please select a Batch No');
      return;
  }
  if (!format) {
      toast.error('Please select a format PDF or Excel');
      return;
  }
    if (format === 'pdf') {
      downloadPDF();
    } else if (format === 'excel') {
      downloadExcel();
    }
    closeModal();
  };

  const downloadPDF = () => {
    const doc = new jsPDF({ orientation: 'portrait', unit: 'mm', format: 'a4' });
    const pageWidth = doc.internal.pageSize.width;
    const margin = 10;
    let currentPage = 1;

    // Define headers and table data for each part
    const headersPart1 = ['Time', 'Material', 'Qty', 'From', 'EbOpening', 'EbClosing', 'LdoOpening', 'LdoClosing'];
    const headersPart2 = ['Reactor', 'ReactorTemp\nOpening', 'ReactorTemp\nClosing', 'ReactorPressure', 'ReceiverPressure','CirculatingPumpPressure', 'Boiler\nOpening', 'Boiler\nClosing'];
    const headersPart3 = ['Water\nOpening','WaterClosing', 'Others', 'Product', 'Pqty', 'Ptank', 'NoOfOperators', 'Remarks'];

    const tableBodyPart1 = logsheetData.map(entry => [
        formatDate(entry.Time), entry.Material, formatNumber(entry.Qty), entry.From, formatNumber(entry.EbOpening), formatNumber(entry.EbClosing), formatNumber(entry.LdoOpening), formatNumber(entry.LdoClosing)
    ]);

    const tableBodyPart2 = logsheetData.map(entry => [
        entry.Reactor,formatNumber(entry.ReactorTempOpening), formatNumber(entry.ReactorTempClosing), formatNumber(entry.ReactorPressure),formatNumber(entry.ReceiverPressure),formatNumber(entry.CirculatingPumpPressure),formatNumber(entry.BoilerOpening),formatNumber(entry.BoilerClosing)
    ]);

    const tableBodyPart3 = logsheetData.map(entry => [
      formatNumber(entry.WaterOpening),formatNumber(entry.WaterClosing), entry.Others, entry.Product, formatNumber(entry.Pqty), entry.Ptank, entry.NoOfOperators, entry.Remarks
    ]);

    const columnStylesPart1 = {
        0: { halign: 'center' },  // Time
        2: { halign: 'center' },  // Qty
        4: { halign: 'center' },  // EbOpening
        5: { halign: 'center' },  // EbClosing
        6: { halign: 'center' },  // LdoOpening
        7: { halign: 'center' }   // LdoClosing
    };

    const columnStylesPart2 = {
        0: { halign: 'center' },  // Reactor
        1: { halign: 'center' },  // ReactorTempOpening
        2: { halign: 'center' },  // ReactorTempClosing
        3: { halign: 'center' },  // ReactorPressure 
        4: { halign: 'center' },  // ReceiverPressure
        5: { halign: 'center' },  // CirculatingPumpPressure
        6: { halign: 'center' },  // BoilerOpening
        7: { halign: 'center' },  // BoilerClosing
        // WaterOpening
    };

    const columnStylesPart3 = {
        0: { halign: 'center' },  // WaterOpening
        1: { halign: 'center' },  // WaterClosing
        2: { halign: 'center' },   // Others
        3: { halign: 'center' },  // Product
        4: { halign: 'center' },  // Pqty
        5: { halign: 'center' },  // Ptank
        6: { halign: 'center' },   // NoOfOperators
        7: { halign: 'center' }    // Remarks
    };

    const commonTableOptions = {
        theme: 'grid',
        styles: {
            fontSize: 8,
            cellPadding: 2,
            lineColor: [128, 128, 128], // Border color to grey
            lineWidth: 0.1,             // Border width
        },
        headStyles: {
            fillColor: [0, 51, 102],
            textColor: [255, 255, 255],
            halign: 'center',
        },
        margin: { left: margin, right: margin },
        pageBreak: 'auto',
        didDrawPage: (data) => {
            doc.setFontSize(10);
            doc.text(`Page ${currentPage}`, pageWidth - margin - 10, doc.internal.pageSize.height - margin);
            currentPage++;
        }
    };

    // Add the heading on the first page
    doc.text(`Logsheet for Batch No: ${batchNo || 'All'}`, margin, margin + 10);

    // Add the first part of the table
    doc.autoTable({
        ...commonTableOptions,
        startY: margin + 20 + 10, // Start Y adjusted to leave space for heading
        head: [headersPart1],
        body: tableBodyPart1,
        columnStyles: columnStylesPart1
    });

    // Add a new page for the second part of the table
    doc.addPage();
    doc.text(`Logsheet for Batch No: ${batchNo || 'All'}`, margin, margin + 10);

    doc.autoTable({
        ...commonTableOptions,
        startY: margin + 20,
        head: [headersPart2],
        body: tableBodyPart2,
        columnStyles: columnStylesPart2
    });

    // Add a new page for the third part of the table
    doc.addPage();
    doc.text(`Logsheet for Batch No: ${batchNo || 'All'}`, margin, margin + 10);

    doc.autoTable({
        ...commonTableOptions,
        startY: margin + 20,
        head: [headersPart3],
        body: tableBodyPart3,
        columnStyles: columnStylesPart3
    });

    // Save the PDF
    doc.save(`BatchNo_${batchNo || 'All'}.pdf`);
};

// Helper function to format the date
const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
};
const formatNumber = (number) => {
  return Number(number).toFixed(2); // Formats number to 2 decimal places
};
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheetName = `BatchNo_${batchNo || 'All'}`;
    const maxSheetNameLength = 31;
    
    // Truncate the sheet name if it exceeds 31 characters
    const truncatedSheetName = sheetName.length > maxSheetNameLength
      ? sheetName.substring(0, maxSheetNameLength)
      : sheetName;
  
    const worksheet = workbook.addWorksheet(truncatedSheetName);
  
    // Define header and add header row
    const headers = [
      'Time', 'Material', 'Qty', 'From', 'EbOpening', 'EbClosing', 'LdoOpening', 'LdoClosing', 'Reactor', 'ReactorTempOpening', 'ReactorTempClosing', 
      'ReactorPressure ', 'ReceiverPressure','CirculatingPumpPressure', 'BoilerOpening', 'BoilerClosing', 'WaterOpening', 'WaterClosing', 'Others', 'Product', 'Pqty', 
      'Ptank', 'NoOfOperators', 'Remarks'
    ];
  
    // Add header row with styles
    worksheet.addRow(headers).eachCell({ includeEmpty: true }, (cell, colNumber) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF003366' } // Navy Blue
      };
      cell.font = { color: { argb: 'FFFFFFFF' }, bold: true }; // White text
      cell.alignment = { horizontal: 'center' }; // Center alignment
    });
  
    // Function to convert ISO time to backend format
  // Function to convert ISO time to backend format
  const convertTime = (timestamp) => {
    return new Date(timestamp).toISOString().slice(0, 19).replace('T', ' ');
  };

    // Add data rows with converted time
    logsheetData.forEach(data => {
      worksheet.addRow([
        convertTime(data.Time),data.Material,parseFloat(data.Qty).toFixed(2), data.From, parseFloat(data.EbOpening).toFixed(2), parseFloat(data.EbClosing).toFixed(2),
          parseFloat(data.LdoOpening).toFixed(2), parseFloat(data.LdoClosing).toFixed(2), data.Reactor, parseFloat(data.ReactorTempOpening).toFixed(2),parseFloat(data.ReactorTempClosing).toFixed(2),
            parseFloat(data.ReactorPressure).toFixed(2),  parseFloat(data.ReceiverPressure).toFixed(2), parseFloat(data.CirculatingPumpPressure).toFixed(2),   parseFloat(data.BoilerOpening).toFixed(2), parseFloat(data.BoilerClosing).toFixed(2),
              parseFloat(data.WaterOpening).toFixed(2), parseFloat(data.WaterClosing).toFixed(2), data.Others, data.Product, parseFloat(data.Pqty).toFixed(2), data.Ptank,
        data.NoOfOperators, data.Remarks
      ]);
    });
  
    // Set column widths and center alignment for all cells
    worksheet.columns.forEach(column => {
      column.width = 20; // Adjust as needed
      column.eachCell({ includeEmpty: true }, cell => {
        cell.alignment = { horizontal: 'center' }; // Center alignment
      });
    });
  
    // Save workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `BatchNo_${batchNo || 'All'}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };




  

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
      <div className="bg-gray-700 p-6 rounded-2xl shadow-lg max-w-sm w-full">
        <h2 className="text-xl font-bold mb-4 text-white">Download Logsheet</h2>

        {/* Batch Number Selection */}
        <Select
  placeholder="Select Batch No (Optional)"
  value={batchOptions.find(option => option.value === batchNo)}
  onChange={(selectedOption) => setBatchNo(selectedOption ? selectedOption.value : '')}
  options={batchOptions}
  className="mb-4 w-full"
  styles={{
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      borderColor: 'white',
      boxShadow: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: '200px',
      overflowY: 'auto', // Ensure scrolling is applied correctly
      marginTop: 0,      // Remove any margin that can cause layout issues
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,         // Ensure no padding interferes with scrolling
      maxHeight: '200px', // Limit menu list height to fit within the container
      overflowY: 'auto',  // Single scrollbar on the menu list
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'white',
    }),
  }}
/>



        {/* Format Selection */}
        <select
          value={format}
          onChange={(e) => setFormat(e.target.value)}
          className="w-full px-2 py-2 mb-4 text-gray-200 bg-gray-700 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 "
        >
          <option value="" disabled >Select Format</option>
          <option value="pdf">PDF</option>
          <option value="excel">Excel</option>
        </select>

        <div className="flex justify-end">
          <button
            onClick={closeModal}
            className=" w-20 px-3 py-1.5 bg-gray-500 text-white text-sm rounded-lg mr-2 hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleDownload}
            className="w-20 px-2 py-1.5 text-sm text-white bg-rose-500 hover:bg-red-700 rounded-lg"
         
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogsheetDownloadModal;
